import { LogoVariant } from '@/components/LogoVariant';
import assetUrl from '@/utilities/assetUrl';
import classnames from 'classnames';

// look at public/assets/<file> and input the size for each file here
const getSizes = (variant: LogoVariant) => {
  switch (variant) {
    case LogoVariant.Main:
    case LogoVariant.MainOnBackground:
      return {
        width: 930,
        height: 170,
        title: 'LOTTO.de',
        alt: 'LOTTO.de-Logo',
      };

    case LogoVariant.Slogan:
    case LogoVariant.SloganGray:
      return {
        width: 205,
        height: 35,
      };
    case LogoVariant.Lotto6aus49:
      return {
        width: 900,
        height: 130,
        title: 'LOTTO 6aus49',
        alt: 'LOTTO 6aus49-Logo',
      };
    case LogoVariant.Lotto6aus49ProductRefresh:
      return {
        width: 334,
        height: 94,
        title: 'LOTTO 6aus49',
        alt: 'LOTTO 6aus49-Logo',
      };
    case LogoVariant.Lotto6aus49Campaign:
      return {
        width: 668,
        height: 162,
        title: 'LOTTO 6aus49',
        alt: 'Wir nehmen Dein Glück ernst',
        type: 'webp',
      };
    case LogoVariant.GluecksSpirale:
      return {
        width: 1195,
        height: 308,
        title: 'GlücksSpirale',
        alt: 'GlücksSpirale-Logo',
        type: 'webp',
      };
    case LogoVariant.GluecksSpiraleBlack:
    case LogoVariant.GluecksSpiraleColor:
      return {
        width: 796,
        height: 223,
        title: 'GlücksSpirale',
        alt: 'GlücksSpirale-Logo',
      };
    case LogoVariant.Eurojackpot:
      return {
        width: 156,
        height: 24,
        title: 'Eurojackpot',
        alt: 'Eurojackpot-Logo',
      };
    case LogoVariant.EurojackpotProductRefresh:
      return {
        width: 218,
        height: 125,
        title: 'Eurojackpot',
        alt: 'Eurojackpot-Logo',
        type: 'webp',
      };
    case LogoVariant.Keno:
      return {
        width: 83,
        height: 24,
        title: 'KENO',
        alt: 'KENO-Logo',
      };
    case LogoVariant.Spiel77:
    case LogoVariant.Spiel77Black:
    case LogoVariant.Spiel77OnBackground:
      return {
        width: 424,
        height: 104,
        title: 'Spiel 77 - Zusatzlotterie',
        alt: 'Spiel 77 - Logo der Zusatzlotterie',
      };
    case LogoVariant.Super6:
    case LogoVariant.Super6Black:
    case LogoVariant.Super6OnBackground:
      return {
        width: 447,
        height: 111,
        title: 'SUPER6 - Zusatzlotterie',
        alt: 'SUPER6 - Logo der Zusatzlotterie',
      };
    case LogoVariant.Plus5:
    case LogoVariant.Plus5OnBackground:
      return {
        width: 56,
        height: 16,
        title: 'plus 5 - Zusatzlotterie',
        alt: 'plus 5 - Logo der Zusatzlotterie',
      };
    case LogoVariant.TotoAW:
      return {
        width: 436,
        height: 64,
        title: 'TOTO 6aus45 Auswahlwette',
        alt: 'TOTO 6aus45 Auswahlwette-Logo',
      };
    case LogoVariant.TotoEW:
      return {
        width: 436,
        height: 67,
        title: 'TOTO 13er Ergebniswette',
        alt: 'TOTO 13er Ergebniswette-Logo',
      };
    case LogoVariant.Toto:
      return {
        width: 436,
        height: 67,
        title: 'TOTO',
        alt: 'TOTO-Logo',
      };
    case LogoVariant.Rubbellose:
      return {
        width: 436,
        height: 67,
        title: 'Rubbellose',
        alt: 'Rubbellose-Logo',
      };
    default:
      console.warn(
        `unknown logo variant "${variant}", may results in flash of unstyled content`
      );
      return undefined;
  }
};

export default function Logo({
  variant,
  title,
  className,
  lazyload,
}: {
  variant: LogoVariant;
  title?: string;
  className?: string;
  lazyload?: boolean;
}) {
  const variantDetails = getSizes(variant);
  const alt = variantDetails?.alt || title || `Logo ${variant}`;
  const suffix = variantDetails?.type ? variantDetails.type : 'svg';
  return (
    <img
      className={classnames('Logo', className)}
      src={assetUrl(`/assets/images/${variant}.${suffix}`)}
      alt={alt}
      {...variantDetails}
      loading={lazyload ? 'lazy' : 'eager'}
      decoding={lazyload ? 'async' : 'auto'}
    />
  );
}
